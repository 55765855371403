<template>
  <span class="secondary--text fs-14px" v-if="parsedValue.Action == 'ShiftContract'">
    <v-alert color="blue-grey" text class="my-1 pa-1">
      <span class="d-flex align-center py-1 fs-15px" style="gap: 0.5rem">
        <i class="fad fa-timer ml-2 mr-1"></i>
        <b><u>Contract Shifted</u></b> by <b>{{ parsedValue.Data.ShiftValue }}</b> months
      </span>
    </v-alert>
  </span>
  <span class="secondary--text fs-14px" v-else-if="parsedValue.Action == 'AddPauseDuration'">
    <v-alert color="blue-grey" text class="my-1 pa-1">
      <span class="d-flex align-center py-1 fs-15px" style="gap: 0.5rem">
        <i class="fad fa-circle-pause ml-2 mr-1"></i>
        <b><u>Paused Duration Added</u></b>
      </span>
      <div class="px-2 pb-0 fs-14px">
        Start Date: <b class="mr-3">{{ parsedValue.Data.StartDate.substr(0, 7) }}</b> Phases Count:
        <b class="mr-3">{{ parsedValue.Data.PhasesCount }}</b> Phase Name:
        <b>{{ parsedValue.Data.Name }}</b>
      </div>
    </v-alert>
  </span>
  <span class="secondary--text fs-14px" v-else-if="parsedValue.Action == 'ApproveContract'">
    <v-alert color="blue-grey" text class="my-1 pa-1">
      <div class="d-flex align-center py-1 fs-15px" style="gap: 0.5rem">
        <i class="fad fa-check ml-2 mr-1"></i>
        <b><u>Contract Approved</u></b> with a note:
      </div>
      <div
        class="tiptap-vuetify-editor__content tiptap-vuetify-editor__preview__readonly px-2 pb-0"
        v-html="
          parsedValue.Data.ApproveNote != null && $stripHtml(parsedValue.Data.ApproveNote) != ''
            ? parsedValue.Data.ApproveNote
            : `<i class='fad fa-empty-set opacity-40 fs-14px'></i>`
        "
      ></div>
    </v-alert>
  </span>
  <span class="secondary--text fs-14px" v-else-if="parsedValue.Action == 'UnApproveContract'">
    <v-alert color="blue-grey" text class="my-1 pa-1">
      <span class="d-flex align-center py-1 fs-15px" style="gap: 0.5rem">
        <i class="fad fa-undo ml-2 mr-1"></i>
        <b><u>Contract UnApproved</u></b>
      </span>
    </v-alert>
  </span>
  <span class="secondary--text fs-14px" v-else-if="parsedValue.Action == 'SendForApproval'">
    <v-alert color="blue-grey" text class="my-1 pa-1">
      <span class="d-flex align-center py-1 fs-15px" style="gap: 0.5rem">
        <i class="fad fa-gavel ml-2 mr-1"></i>
        <b><u>Contract Sent For Approval</u></b>
      </span>
    </v-alert>
  </span>
  <span class="secondary--text fs-14px" v-else-if="parsedValue.Action == 'SendForUpdate'">
    <v-alert color="blue-grey" text class="my-1 pa-1">
      <span class="d-flex align-center py-1 fs-15px" style="gap: 0.5rem">
        <i class="fad fa-edit ml-2 mr-1"></i>
        <b><u>Contract Sent For Update</u></b>
      </span>
    </v-alert>
  </span>
  <span class="secondary--text fs-14px" v-else-if="parsedValue.Action == 'LockContract'">
    <v-alert color="blue-grey" text class="my-1 pa-1">
      <span class="d-flex align-center py-1 fs-15px" style="gap: 0.5rem">
        <i class="fad fa-lock-keyhole ml-2 mr-1"></i>
        <b><u>Contract Locked</u></b>
      </span>
    </v-alert>
  </span>
  <span class="secondary--text fs-14px" v-else-if="parsedValue.Action == 'UnLockContract'">
    <v-alert color="blue-grey" text class="my-1 pa-1">
      <span class="d-flex align-center py-1 fs-15px" style="gap: 0.5rem">
        <i class="fad fa-lock-keyhole-open ml-2 mr-1"></i>
        <b><u>Contract UnLocked</u></b>
      </span>
    </v-alert>
  </span>
  <span class="secondary--text fs-14px" v-else-if="parsedValue.Action == 'DeleteContract'">
    <v-alert color="blue-grey" text class="my-1 pa-1">
      <span class="d-flex align-center py-1 fs-15px" style="gap: 0.5rem">
        <i class="fad fa-trash-alt ml-2 mr-1"></i>
        <b><u>Contract Deleted</u></b>
      </span>
    </v-alert>
  </span>
  <span class="secondary--text fs-14px" v-else-if="parsedValue.Action == 'ArchiveContract'">
    <v-alert color="blue-grey" text class="my-1 pa-1">
      <div class="d-flex align-center py-1 fs-15px" style="gap: 0.5rem">
        <i class="fad fa-box-archive ml-2 mr-1"></i>
        <b><u>Contract Archived</u></b> with a note:
      </div>
      <div
        class="tiptap-vuetify-editor__content tiptap-vuetify-editor__preview__readonly px-2 pb-0 pt-1"
        v-html="
          parsedValue.Data.ArchiveNote != null && $stripHtml(parsedValue.Data.ArchiveNote) != ''
            ? parsedValue.Data.ArchiveNote
            : `<i class='fad fa-empty-set opacity-40 fs-14px'></i>`
        "
      ></div>
    </v-alert>
  </span>
  <span class="secondary--text fs-14px" v-else-if="parsedValue.Action == 'UnarchiveContract'">
    <v-alert color="blue-grey" text class="my-1 pa-1">
      <div class="d-flex align-center py-1 fs-15px" style="gap: 0.5rem">
        <i class="fad fa-inbox-out ml-2 mr-1"></i>
        <b><u>Contract Unarchived</u></b> with a note:
      </div>
      <div
        class="tiptap-vuetify-editor__content tiptap-vuetify-editor__preview__readonly px-2 pb-0 pt-1"
        v-html="
          parsedValue.Data.UnArchiveNote != null && $stripHtml(parsedValue.Data.UnArchiveNote) != ''
            ? parsedValue.Data.UnArchiveNote
            : `<i class='fad fa-empty-set opacity-40 fs-14px'></i>`
        "
      ></div>
    </v-alert>
  </span>
</template>

<script>
export default {
  components: {},
  name: "related-actions-logs",
  inheritAttrs: false,
  data() {
    return {};
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  computed: {
    parsedValue() {
      return JSON.parse(this.value);
    },
  },
  methods: {},
};
</script>

<style lang="scss"></style>
